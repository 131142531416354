import Service from './Service';

class EdrCheckinService extends Service {
    constructor() {
        super();
    }
    async checkinTrackingCurrent(collegData) {
        return this.callApiGetForEdr(`/checkinTrackingCurrent`, collegData);
    }
    async checkinTrackingUserTypeChart(collegData) {
        return this.callApiGetForEdr(`/checkinTrackingUserTypeChart`, collegData);
    }
    async checkinTrackingLatestCheck(collegData) {
        return this.callApiGetForEdr(`/checkinTrackingLatestCheck`, collegData);
    }

    async contactTrackingChartUpdate(collegData) {
        return this.callApiGetForEdr(`/contactTrackingChartUpdate`, collegData);
    }
    async contactTrackingCheckIn(postData, collegData) {
        return this.callApiPostForEdr(`/contactTrackingCheckIn`, postData, collegData);
    }
    async contactTrackingCheckOut(token, postData, collegData) {
        return this.callApiPostForEdr(`/contactTrackingCheckIn?token=${token}`, postData, collegData);
    }

}
export default EdrCheckinService
